<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top" style="position: fixed;height: 50px;">
            <router-link to="/return-list" style="margin-left: 20px;font-size: 32px;">
                    <b-icon icon="arrow-left" font-scale="1"></b-icon>
            </router-link>
            <span>Add Return</span>
        </b-navbar>
        <div class="container-fluid p-2" style="margin-top: 16%;">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div  style="margin-bottom: 100px;" class="col-md-12">
                <form v-on:submit.prevent="onSubmit">
                    <div class="form-group ">
                        <label for="inputDistributor">Distributor</label>
                        <select v-model="returnRequest.distributorId" @change="getReturn()" class="form-control"
                            id="inputDistributor">
                            <option value="">Select Distributor</option>
                            <option :value="distributor.id" v-for="distributor in distributors">
                                {{distributor.name}}</option>
                        </select>
                        <small class="text-danger">{{validationData.distributor}}</small>
                    </div>
                    <div class="form-group ">
                        <small class="text-danger">{{alert}}</small>
                    </div>
                    <div v-for="item in returnItem">
                        <div class="form-group" >
                            <label>{{item.name}}</label>
                            <select class="form-control" v-model="item.remainQty">
                                <option :value="remain" v-for="remain in item.remain">
                                    {{remain}}</option>
                            </select>
                        </div>
                        <div class="form-group" >
                            <label>Damage</label>
                            <input type="number" class="form-control" v-model="item.damage">
                        </div>
                        <hr>
                    </div>
                    <div class="form-group " v-if="returnItem.length > 0">
                        <label for="inputDistributor">Remark</label>
                        <textarea class="form-control" v-model="returnRequest.remark"></textarea>
                    </div>
                    <div class="form-group row" v-if="returnItem.length > 0">
                        <div class="col-sm-12">
                            <button type="submit" class="btn btn-primary mr-2" style="width: 100%;">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'location',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                message: "",
                alert: "",
                distributors: [],
                returnItem: [],
                returnRequest: {
                    distributorId: "",
                    remark: "",
                },
                validationData: {
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                distributorListAction: 'distributorListAction',
                returnItemAction: 'returnItemAction',
                returnStoreAction: 'returnStoreAction'
            }),
            async distributorList() {
                this.isLoading = true;
                await this.distributorListAction().then(res => {
                    if (res.status == "success") {
                        this.distributors = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async onSubmit() {
                this.isLoading = true
                let validation = this.validationRule()
                if (validation == true) {
                    this.isLoading = false
                    return
                }
                let option = {
                    distributorId: this.returnRequest.distributorId,
                    remark: this.returnRequest.remark,
                    returnItem: this.returnItem
                }
                await this.returnStoreAction({
                    ...option
                }).then(res => {
                    if(res.status == "fail"){
                        this.alert = "At Least Choose One Item"
                    }else{
                        this.$router.replace('/consumer-return-list')
                    }
                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
            async getReturn() {
                this.isLoading = true
                let option = {
				    distributorId: this.returnRequest.distributorId
				}
                await this.returnItemAction({
                    ...option
                }).then(res => {
                    this.returnItem = res.data
                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
            validationRule() {
                let isValidation = []
                if (isValidation.includes(true)) {
                    return true
                } else {
                    return false
                }
            }
        },
        async mounted() {
            this.distributorList()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>